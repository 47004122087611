<template lang="">
    <div class="card">
        <div class="card-header border-0">
            <div class="card-title w-100 d-flex justify-content-between">
                <h1>Dersler </h1>
                <a v-if="false" href="#"
                data-bs-toggle="modal"
                @click="school = {}"
                id="marka_ekle_buton"
                data-bs-target="#kt_modal_add_school" class="btn btn-primary">
                <span class="svg-icon svg-icon-1"><i class="fa fa-plus"></i> </span>Yeni Okul</a>
            </div>
        </div>
        <div class="card-body pt-0 pb-5">
            <veri-tablo :datas="datas" :headers="tableHeader" :links="links" :metas="metas">
                <template v-slot:cell-title="{ row: item }">
                    {{ item.title }}
                </template>
                <template v-slot:cell-type="{ row: item }">
                    {{ item.type }}                    
                </template>
                <template v-slot:cell-actions="{row:item}">
                    <el-dropdown trigger="click">
                        <span class="el-dropdown-link rounded p-3 bg-light">
                            İşlemler<el-icon class="el-icon--right"><arrow-down /></el-icon>
                        </span>
                        <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item>Görüntüle</el-dropdown-item>
                            <el-dropdown-item @click="school = item">
                               <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_update_school"  style="color:var(--el-text-color-regular);"> Güncelle </a>
                            </el-dropdown-item>
                            <el-dropdown-item @click="deleteItem(item)" >Sil</el-dropdown-item>
                        </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
            </veri-tablo>
        </div>
    </div>
    <empty-modal modalId="kt_modal_add_school" ref="eklemodal__ref" modalTitle="Okul Oluştur"> 
        <template v-slot:ModalBody>
            <div class="row">
                <div class="col"> 
                    <div class="row">
                        <div class="col-md-6"> 
                            <div class="input-group mb-5">
                                <label for="" class="form-label">Okul Adı</label>
                                <input type="text  w-100" v-model="school.title" class="form-control w-100" placeholder="Marka Adı" aria-label="Username" aria-describedby="basic-addon1"/>
                            </div>
                            
                        </div>
                        <div class="col-md-6">
                            <div class="input-group mb-5">     
                                <label for="" class="form-label">Okul Tipi</label>     
                                <input type="text" style="width:100%;" v-model="school.type" class="form-control" placeholder="Açıklama" aria-label="Username" aria-describedby="basic-addon1"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input-group mb-5">     
                                <label for="" class="form-label">Aktiv mi?</label>     
                                <input type="text" style="width:100%;" v-model="school.active" class="form-control" placeholder="Açıklama" aria-label="Username" aria-describedby="basic-addon1"/>
                            </div>
                        </div>

                    </div>
                    
                </div>
                
            </div>

            
        </template>
        <template v-slot:ModalFooter>
            <a 
                @click="save(school)"
                class="btn btn-primary"> 
                Kaydet
            </a>
        </template>
    </empty-modal>
    <empty-modal modalId="kt_modal_update_school" ref="gucellememodal__ref" modalTitle="Ders Güncelle"> 
        <template v-slot:ModalBody>
            <div class="row">
                <div class="col-md-6"> 
                    <div class="input-group mb-5">
                        <label for="" class="form-label">Okul Adı </label>
                        <input type="text" v-model="school.title" class="form-control w-100"  aria-describedby="basic-addon1"/>
                    </div>
                </div>
                 <div class="col-md-6"> 
                    <div class="input-group mb-5">
                        <label for="" class="form-label">Okul Tipi </label>
                        <input type="text" v-model="school.type" class="form-control w-100"  aria-describedby="basic-addon1"/>
                    </div>
                </div>
  
            </div>

        </template>
        <template v-slot:ModalFooter>
            <a 
            id="marka_ekle_buton"
            @click="update(school)"
            class="btn btn-primary"> 
                Kaydet
            </a>
            <button 
            @click="gucellememodal__ref.close()"
            id="marka_ekle_buton"
            class="btn btn-danger"> 
                Kapat
            </button>
        </template>
    </empty-modal>

</template>
<script>

import { useStore } from "vuex";
import { ArrowDown } from '@element-plus/icons-vue';
import { computed, onMounted ,reactive, ref } from "vue";
import store from '@/store';
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import VeriTablo from '@/components/tablo/VeriTablo.vue'
import EmptyModal from '@/components/modals/forms/EmptyModal.vue';
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { inject } from 'vue';
import  useCruds  from '@/composables/useCrud.js';


export default {
  components: {
    VeriTablo,
    EmptyModal,
    ArrowDown,
    
    
  },
    setup() {
        const school = ref({title:'',type:'',});
        const slug = ref("/schools");
        const eklemodal__ref = ref();
        const gucellememodal__ref = ref();

        const { saveCrud, updateCrud, deleteCrud,loadCrud } = useCruds();

        const tableHeader = [
            {
                name: "Okul Adı",
                key:'title'
            },
            {
                name: "Okul Tipi",
                key: "type",
            },
            {
                name: "İşlemler",
                key: "actions",
            },
        ];

        const store = useStore();
        const router = useRouter();

        const datas = ref({});
        const metas = ref({});
        const links = ref({});
  
       
        const save = (element) => {
            let payload = {};
            payload.data = element;
            payload.slug = slug.value;

            saveCrud(payload).then(
                function(value) {
                    datas.value.unshift(value.data);
                    eklemodal__ref.value.close();
                }
            );
        };
        const update = (element) => {
            
            let payload = {};
            payload.slug = slug.value+"/"+element.schoolId;
            payload.data = element;
            updateCrud(payload).then(
                function(value) { 
                    gucellememodal__ref.value.close();
                }
            );
        };
        const emitter = inject('emitter');

        const deleteItem = (item) => {
            console.log(item);
            let payload = {};
            payload.slug = slug.value+"/"+item.schoolId;
            deleteCrud(payload).then(function(value){
                if(value){
                    for (let i = 0; i < datas.value.length; i++) {
                        if (datas.value[i] === item) {
                            datas.value.splice(i, 1);
                        }
                    }
                }
            });
            
        };

        onMounted(() => {
            loadCrud(slug.value).then(function(response){
                if(response.success){

                    datas.value = response.data;
                    links.value = response.links;
                    metas.value = response.meta;
                }
            });
             emitter.on('PageButtonClicked', (value) => {     
                if(router.currentRoute.value.path == "/schools"){
                    loadCrud(slug.value, value).then(function(response){
                        if(response.success){
                            datas.value = response.data;
                            links.value = response.links;
                            metas.value = response.meta;
                        }
                    });
                }
            });

            
        });
       
        return {
            tableHeader,
            datas,
            school,
            deleteItem,
            save,
            update,
            links,
            metas,
            eklemodal__ref,
            gucellememodal__ref,
           
        }
    }
}


</script>
<style lang="css">
   .colorful-div{
        width:100px;
        height:24px;
        border-radius:8px;
        padding:5px;
        background:#fff;
        font-family: 'Comfortaa', cursive;
        cursor:pointer;
        color:#fff;
        margin:auto;
   }
   
   .badge{
       width:24px;
       height:24px;
       display:flex;
       justify-content:center;
       align-items:center;
       align-content:center;
   }

    .avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    }

</style>


<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>